import React, { useState, useRef } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import Loader from '../../images/svgs/loader.svg'

export default function MailchimpForm ( props ) {

	const form = useRef();
	const [ firstName, setFirstName ] = useState('')
	const [ lastName, setLastName ] = useState('')
	const [ email, setEmail ] = useState( '' )
	const [ formSuccess, setFormSuccess ] = useState( false )
	const [ error, setError ] = useState('')
	const [ loading, setLoading ] = useState()

	const handleSubmit = async ( event ) => {
		event.preventDefault()
		form.current.reportValidity();

		setLoading( true )

		addToMailchimp( email, { FNAME: firstName, LNAME: lastName } )
			.then( data => {
				setLoading( false )
				setFormSuccess( true )
			} )
			.catch( () => {
				setLoading( false )
				setError( true )
			} )
	}

	return (
		<>
			{ !formSuccess ? (
				<form className="form" onSubmit={ handleSubmit } ref={ form }>
					<div className={`form__input-container ${ error ? 'error' : '' }`}>
						<label className="form__label" htmlFor="name">First name</label>
						<input
							onChange={ e => { setFirstName(e.target.value) }}
							value={ firstName }
							className="form__input form__input--blue" id="name" type="text" placeholder="First name" required />
					</div>

					<div className={`form__input-container ${ error ? 'error' : '' }`}>
						<label className="form__label" htmlFor="name">Last name</label>
						<input
							onChange={ e => { setLastName(e.target.value) }}
							value={ lastName }
							className="form__input form__input--yellow" id="name" type="text" placeholder="Last name" required />
					</div>

					<div className={`form__input-container ${ error ? 'error' : '' }`}>
						<label className="form__label" htmlFor="email">Email</label>
						<input
							onChange={ e => { setEmail(e.target.value) }}
							value={ email }
							className="form__input form__input--green" id="email" type="email" placeholder="Email" required />
					</div>

					<input type="hidden" id="" name="" value=""></input>

					{ error.length >= 1 ? (
						<div className="form__error-container">
							<span className="form__error-message">Sorry, we have been unable to sign you up.</span>
							<span className="form__error-message">Please try again later.</span>
						</div>
					) : (
						<button className="form__submit" type="submit">Send</button>
					) }

					{ loading &&
						<div className="form__loading-container">
							<div className="form__loading-background"></div>
							<Loader className="loader" />
							<h3 className="form__loading-text">Sending</h3>
						</div>
					}
				</form>
			) : (
				<p className="form__success">
					Hi { firstName },
					<span>Welcome to Arcipluvia and thank you for subscribing. Don't worry, we won't bombard you with emails but we'll keep you updated on new works and exciting projects.</span>
				</p>
			) }
		</>
	)
}