import React, { useEffect, useRef } from 'react'

import MailchimpForm from '../Mailchimp/MailchimpForm'

import PlusIcon from '../../images/svgs/plus-icon.svg'

export default function Sidebar() {
 
	const sidebarRef = useRef()

	const handleClose = () => {
		localStorage.setItem( 'sideabr-subscribe-closed', true );
		sidebarRef.current.classList.add( 'is-hidden' )
	}

	const checkCookieOnLoad = () => {
		const cookiePolicyChosen  = localStorage.getItem( 'sideabr-subscribe-closed' )

		if ( !cookiePolicyChosen || cookiePolicyChosen !== 'true' ) {
			setTimeout(() => {
				sidebarRef.current.classList.remove( 'is-hidden' )
			}, 2000)
		}
	};

	useEffect(() => {
		checkCookieOnLoad()
	}, [] )

	return (
		<div className="sidebar is-hidden" ref={ sidebarRef }>
			<div className="sidebar__toggle">
				Subscribe to Newsletter
				<PlusIcon className="sidebar__close" onClick={() => handleClose()} />
			</div>
			<div className="sidebar__container">
				<MailchimpForm />
			</div>
		</div>
	)
}